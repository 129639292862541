import localeService from '@/services/localeService';
import isNumber from 'lodash/isNumber';

export default {
  filters: {
    currency (value, currency = null, locale = null) {
      if (!isNumber(value)) {
        return value;
      }
      locale = locale || localeService.currentLocale;
      currency = currency || localeService.currentCurrency;
      let numberFormat;
      if (locale === 'en-GB') {
        numberFormat = Intl.NumberFormat(locale, { style: 'currency', currency }).format(value).substring(1) + '£';
      }
      if (locale === 'nl-NL') {
        numberFormat = Intl.NumberFormat(locale, { style: 'currency', currency }).format(value).substring(1) + '€';
      }
      return (locale === 'en-GB' || locale === 'nl-NL') ? numberFormat : Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
    },
    currencySymbol (currency, locale = null) {
      locale = locale || localeService.currentLocale;
      currency = currency || localeService.currentCurrency;
      const parts = Intl.NumberFormat(locale, { style: 'currency', currency }).formatToParts();
      for (const part of parts) {
        if (part.type === 'currency') {
          return part.value;
        }
      }
      return currency;
    },
    number (value, locale = null) {
      if (!isNumber(value)) {
        return value;
      }
      locale = locale || localeService.currentLocale;
      return Intl.NumberFormat(locale).format(value);
    }
  },
  methods: {
    applyCurrencyFilter (number, currencyValue) {
      return this.$options.filters.currency(number, currencyValue);
    },
    getCurrencySymbol (currency, locale = null) {
      return this.$options.filters.currencySymbol(currency, locale);
    },
    /**
     * Sums an array of numeric elements. You should only use this reducer within a reduce method.
     *
     * Example:
     * [1, 2, 3].reduce(this.sumReducer, 0) // returns 6
     *
     * A second parameter should always be provided in case of a dynamic array, it's the initial value:
     *
     * Example:
     * [1, 2, 3].reduce(this.sumReducer) // returns 6
     * [].reduce(this.sumReducer) // throws: TypeError: Reduce of empty array with no initial value
     * [].reduce(this.sumReducer, 0) // returns 0
     */
    sumReducer: (accumulator, currentValue) => accumulator + currentValue,
    countDecimals (number) {
      if (Math.floor(number.valueOf()) === number.valueOf()) {
        return 0;
      }
      return number.toString().split('.')[1]?.length || 0;
    }
  }
};
