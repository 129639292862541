import moment from 'moment-timezone';

export default {
  filters: {
    /**
     * Get the date in locale format
     * Exemple: 20/01/2021
     * @param {String|Date|moment} value - date value
     */
    date (value) {
      if (!value) {
        return value;
      }
      return moment.tz(value, moment.tz.guess()).format('L');
    },
    /**
     * Get the date-time in locale format
     * Exemple: 20/01/2021 08:58 or 20/01/2021 08:58:54
     * @param {String|Date|moment} value - date value
     * @param {Object} params
     * @param {Boolean} params.withSeconds show seconds, defaults to false
     */
    dateTime (value, { withSeconds } = {}) {
      if (!value) {
        return value;
      }
      return moment.tz(value, moment.tz.guess()).format(`L LT${withSeconds ? 'S' : ''}`);
    },
    /**
     * Get the time in locale format
     * Exemple: 08:58 or 08:58:54
     * @param {String|Date|moment} value - date value
     * @param {Object} params
     * @param {Boolean} params.withSeconds show seconds, defaults to false
     */
    time (value, { withSeconds } = {}) {
      if (!value) {
        return value;
      }
      return moment.tz(value, moment.tz.guess()).format(`LT${withSeconds ? 'S' : ''}`);
    },
    /**
     * Get a string representation of the time between the given date and now
     * Example: a few seconds ago
     * @param {String|Date|moment} value - date value
     */
    fromNow (value) {
      if (!value) {
        return value;
      }
      return moment.tz(value, moment.tz.guess()).fromNow();
    }
  },
  methods: {
    isBeforeToday (value) {
      if (!value) {
        return false;
      }
      return moment.utc(value).isBefore(moment.utc());
    },
    daysSince (value) {
      if (!value) {
        return value;
      }
      return moment.utc().diff(moment.utc(value), 'days');
    },
    parseDate (date) {
      return moment(date).format('YYYY-MM-DD');
    },
    getCurrentDate (withoutDay = false) {
      let format = 'YYYY-MM-DD';
      if (withoutDay) {
        format = 'YYYY-MM';
      }
      return moment().tz('Europe/Paris').format(format);
    },
    getFirstDayOfMonth (date) {
      return moment(date).startOf('month').format('YYYY-MM-DD');
    },
    getLastDayOfMonth (date) {
      return moment(date).endOf('month').format('YYYY-MM-DD');
    }
  }
};
